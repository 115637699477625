body{
    /*background: #f0ece2 url("../img/background.jpg") center center no-repeat;*/
    /*background: #faf5ef;
    background-size: cover;*/
    font-family: 'Ubuntu', sans-serif;
}

*{
    margin: 0;
    padding: 0;
    list-style: none;
    outline: none;
}
button, .login-button, .menu-button{
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 0;
}
.login-button, .menu-button, .menu-close-button, .big-line-chart-menu ul>li button,
.show-profile-button, .change-password-button{
    background: none !important;
    border: 0 !important;
    text-align: center;
    width: 100%;
    appearance: none !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    outline: none !important;
    cursor: pointer;
}
.app-home:before{

}
.search-wrapper{

}
.order-form-container{

}
.order-form-wrapper{

}
.order-form-wrapper2, .login-wrapper{
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    margin: auto;
    position: relative;
    -webkit-box-shadow: 0 0 5px rgba(0,0,0,.4);
    -moz-box-shadow: 0 0 5px rgba(0,0,0,.4);
    box-shadow: 0 0 5px rgba(0,0,0,.4);
    background: #FFF;
}
.order-form-wrapper2{
    max-width: 800px;
    padding-bottom: 1px;
}
.order-form-container{
    /*background: #0917a9;*/
    padding: 10px 30px;
}
.order-form-container{

}
.order-form-container2{
    /*background: #fafafa;*/
    border-top: 1px solid #0917a9;
    padding: 20px 15px 0;
    margin: 0;
}
.order-form-wrapper h2{
    /*text-align: center;
    font-weight: 700 !important;
    color: #ff6768;
    font-size: 32px;*/
}
.order-form-wrapper h3{
    text-align: center;
    margin-bottom: 30px;
    font-weight: 300;
    color: #FFF;
    font-size: 28px;
}
.pickup-container{
    position: relative;
    padding-bottom: 10px;
}
.pickup-container label{
    font-weight: 300;
    width: 100%;
    font-size: 20px;
    color: #0917a9;
}
.pickup-container input[type=text],
.pickup-container input[type=password],
.pickup-container input[type=email],
.pickup-container input[type=number]{
    width: 100%;
    padding: 8px 16px 8px 48px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #0917a9;
}
.pickup-container input[type=number]{
    text-align: center;
}
.pickup-container .input-icon{
    position: absolute;
    left: 26px;
    top: 39px;
    width: 40px;
    height: 40px;
}
.pickup-point .input-icon i{
    color: #10102c;
}
.delivery-point .input-icon i{
    color: #0917a9;
}
.input-icon i{
    line-height: 40px;
}
.current-selected-type button{
    color: #fff;
    background: #5a1860 !important;
    border: 1px solid #5a1860;
}
.pickup-container .my-location-icon{
    right: 23px !important;
    position: absolute;
    top: 46px;
    cursor: pointer;
    background: #FFF;
}
.get-prices-button-wrapper{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.get-prices-button, .get-prices-button2{
    display: block;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 12px 0;
    text-align: center;
    color: #FFF;
    background: #10102c;
    border: 0;
    font-size: 22px;
}

.place-order-button-wrapper{
    margin: 30px 30px;
}
.get-prices-button2{
    background: #ff6768 !important;
    width: 100%;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-top: 2px solid #ff6768;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    box-shadow: 3px 3px 1px rgba(0,0,0,.8);
}
.get-prices-button{
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    -webkit-border-bottom-left-radius: 8px;
    -webkit-border-bottom-right-radius: 8px;

    -moz-border-radius-bottomleft: 8px;
    -moz-border-radius-bottomright: 8px;
    width: 100%;
}
.delivery-point label{
    color: #591b5f;
}
.delivery-point input[type=text]{
    border: 1px solid #591b5f;
}
.search-area, .login-area{
    /*padding: 250px 0 332px 0 !important;*/
    padding: 189px 0 120px 0 !important

}
.autocomplete-dropdown-container{
    position: absolute;
    top: 88px;
    left: 13px;
    width: 100%;
    padding: 16px 26px;
    z-index: 1;
    border-radius: 7px;
    overflow: hidden;
    background: #10102c;
}
.suggestion-item, .suggestion-item--active{
    background: none !important;
    color: #FFF;
    padding: 8px 0;
    border-bottom: 1px solid rgba(0,0,0,0);
}
.suggestion-item:hover{
    background: none !important;
}
.content-wrapper{
    /*background: #FFF;*/
}
.delivery-detail-container{

}
.maps-container{
    height: 90%;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    background: rgba(9, 23, 169, 0);
    padding-top: 100px;
}
.maps-container{

}
.search-result-container{
    position: absolute;
    z-index: 2;
    background: #FFF;
    padding: 0 0 10px;
    /* margin-top: 0; */
    width: 450px;
    /*border-radius: 2px;*/
    overflow: hidden;
    /* height: 100%; */
    margin-top: 15px;
    left: 40px;
    border-radius: 1px;
    -moz-border-radius: 1px;
    border: 0px solid #000;
}
.search-result-container h3{
    border-bottom: 1px solid #10102c;
    padding: 16px 20px;
    margin: 0;
    font-weight: 300;
    background: #fbfbfb;
    color: #10102c;
}
.delivery-detail-item{
    padding: 16px 20px 0;
    border-bottom: 1px solid #eee;
}
.delivery-detail-item label, .delivery-detail-item p{
    float: left;
    font-size: 16px;
}
.delivery-detail-item label{
    width: 20%;
    font-weight: bold;
    color: #10102c;
}
.delivery-detail-item label i{
    float: left;
    margin-top: 2px;
}
.delivery-detail-item p{
    width: 80%;
}
.delivery-detail-item-last{
    border: 0;
}
.delivery-detail-item p input[type=text]{
    width: 100%;
    padding: 4px 16px;
}
.distance-text{
    float: right;
}
.heading-no-border{
    border: 0 !important;
    background: #fff !important;
    color: #000 !important;
    margin-bottom: 0 !important;
    border-bottom: 1px solid rgba(0,0,0,.1) !important;
    padding-bottom: 16px;
    font-size: 18px;
}
.total-row{
    font-weight: 700 !important;
    border-top: 1px solid rgba(0,0,0,.5) !important;
    border-bottom: 1px solid rgba(0,0,0,.5) !important;
}
.first-price-row{
    border-top: 1px solid rgba(0,0,0,.5) !important;
}
.infoWindowPickup{

}
.search-result-container>div{
    width: 100%;
}
.price-break{
    padding-top: 6px;
    margin-top: 10px;
    clear: both;
    border-top: 1px solid rgba(0,0,0,.5);
    height: 12px;
}
.price-break span{
    font-size: 12px !important;
    color: #000;
}
.price-distance{
    float: left;
}
.price-base{
    float: right;
}
.clear{
    clear: both;
}
.delivery-type-wrapper ul>li{
    float: left;
    text-align: center;
    width: 32%;
    margin-right: 6px;
    border: 1px solid #0917a9;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    cursor: pointer;
    color: #0917a9;
}
.delivery-type-wrapper ul>li button{
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: none;
    cursor: pointer;
    width: 100%;
    padding: 7px 0;
    outline: none;
}
.delivery-type-wrapper ul>li:last-child{
    float: right;
    margin-right: 0;
}
.login-full-height{

}
.login-area{
    background: #0917a9;
    min-height: 300px;
    height: 100vh;
    /*overflow-y: auto;*/
}

.login-wrapper{
    max-width: 450px;
    margin: auto;
    background: #FFF;
    position: relative;
    overflow: hidden;
}
.login-button{
    margin: 0 30px;
}
.login-button>button{
    display: block;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 10px 0;
    text-align: center;
    color: #FFF;
    background: #10102c;
    border: 0;
    font-size: 18px;
    width: 100%;
    border-radius: 4px;
    -moz-border-radius: 4px;
}
.login-wrapper h2{
    font-weight: 400;
    border-bottom: 1px solid #0917a9;
    padding: 16px 20px;
    font-size: 30px;
    text-align: center;
    background: #fbfbfb;
}
.login-wrapper label{
    font-size: 18px !important;
    color: #10102c !important;
}
.register-wrapper label{
    display: none;
}
.login-wrapper .pickup-container{
    padding-left: 20px !important;
    padding-right: 20px !important;
}
.login-wrapper .pickup-container .input-icon{
    left: 29px;
    top: 37px;
}
.register-wrapper .pickup-container .input-icon{
    top: 0 !important;
}
.pickup-container .input-icon i{
    color: #10102c !important;
}
.new-user-container{
    padding: 20px;
    background: #fff;
    border-top: 1px solid #0917a9;
    margin-top: 20px;
    text-align: center;
}
.new-user-container p{
    color: #10102c !important;
}
.navbar-area{
    background: #5a1860;
    /*background: #fa6061;*/
}
.gm-style .gm-style-iw-c button{
    display: none !important;
}
.gm-style .gm-style-iw-c{
    background-color: #17223b !important;
    border-radius: 2px !important;
    -moz-border-radius: 2px !important;
    padding: 12px;
    color: #FFF !important;
    font-size: 16px !important;
}
.gm-style .gm-style-iw-t::after{
    background-color: #17223b !important;
}
.gm-style-iw-d{
    overflow: visible !important;
    padding-bottom: 16px;
    padding-right: 16px;
}
/*Orange: #ff6600*/

.login-loading-container{
    text-align: center;
}

.profile-wrapper{
    /*overflow-y: auto;*/
    /*padding-top: 97px;*/
}
.dashboard-menu{

}
.dashboard-menu ul{
    margin-top: 97px;
    padding: 30px 20px;
}
.dashboard-menu ul>li>a{
    text-align: left;
    color: #fbfbfb;
    display: block;
    padding: 10px 16px;
    border-bottom: 1px solid rgba(255,255,255,.3);
}
.header-area{
    background: none !important;
}
.create-new-order-heading{
    text-align: left;
    color: #17223b;
    font-weight: 700;
    font-size: 24px;
    padding: 13px 30px;
    border-bottom: 1px solid rgba(107,119,141,.5);
    background: rgba(107,119,141,.1);
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    -moz-border-radius-topleft: 8px;
    -moz-border-radius-topright: 8px;
}
.main-delivery-introduction h1{
    font-size: 50px !important;
    font-weight: 600;
    margin-bottom: 20px;
    color: #000;
}
.main-delivery-introduction p{
    color: #000 !important;
}
.get-it-on-playstore{
    margin-top: 35px;
    padding-top: 30px;
    border-top: 1px solid rgba(107,119,141,.5);
}
.get-it-on-playstore p{
    margin-bottom: 20px;
}
.get-it-on-playstore img{
    width: 175px;
    margin-right: 10px;
}
.home-login-button,
.home-signup-button{
    display: inline-block;
    padding: 12px 50px;
    border-radius: 10px;
    border: 1px solid #17223b;
    font-size: 20px;
    font-weight: 500;
    box-shadow: 3px 3px 1px rgba(0,0,0,.8);
}
.home-login-button:hover,
.home-signup-button:hover{
    color: #000;
}
.home-login-button{
    margin-right: 20px;
    color: #17223b;
    background: #FFF;
}
.home-signup-button{
    background: #ff6768;
    color: #FFF;
}
.app-home{

}
.profile-content{

}
.profile-content h2{
    font-weight: 400 !important;
    padding-top: 16px;
    font-size: 36px;
    color: #000;
    line-height: 44px
}
.dashboard-nav{
    height: 70px;
    clear: both;
}
.dashboard-nav h2, .dashboard-nav i{
    float: left;
}
.dashboard-nav i{
    height: 70px;
    line-height: 79px;
    margin-right: 16px;
    cursor: pointer;
    overflow: hidden;
}




.order-detail-item{
    padding: 16px 20px 0;
    border-bottom: 0px solid #eee;
}
.order-detail-item label, .order-detail-item p{
    float: left;
    font-size: 16px;
}
.order-detail-item label{
    width: 20%;
    font-weight: bold;
    color: #10102c;
}
.order-detail-item label i{
    float: left;
    margin-top: 2px;
}
.order-detail-item p{
    width: 80%;
}
.order-detail-item-last{
    border: 0;
}
.profile-menu-wrapper{
    height: 100vh;
}
.task-maps-container{
    width: 100%;
    position: relative;
    height: 80vh;
}
.order-detail-container{
    padding-top: 30px;
    float: left;
    background: #FFF;
    position: absolute;
    left: 10px;
    top: 65px;
    width: 400px;
    z-index: 99;
    box-shadow: 0 0 1px rgba(0,0,0,.1);
    border-radius: 0px;
    overflow: hidden;
}
.task-table{
    background: #fff;
}
.dashboard-box-container{
    padding: 20px;
}
.dashboard-box-container>ul>li{
    background: #fff;
    color: #000;
    padding: 12px 0;
    margin-bottom: 12px;
    position: relative;
}
.dashboard-box-container>ul>li:before{
    content:'';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 4px;

    background: rgb(104,131,187);
    background: linear-gradient(90deg, rgba(104,131,187,1) 0%, rgba(158,180,221,0.5) 100%);
}
.dashboard-box-container>ul>li:last-child{
    margin-right: 0;
}
.dashboard-box-container>ul>li h5{
    font-size: 40px;
    padding: 30px 0;
    font-weight: 300;
    margin: 0;
}
.dashboard-box-container>ul>li h4{
    border-top: 1px solid #eee;
    font-weight: 400;
    padding: 20px 0;
    background: #fbfbfb;
    margin: 0;
}
header{
    background: #243e90;
    height: 60px;
    position: relative
}
header .logo-container{
    position: absolute;
    right: 20px;
    top: 60px;
    background: url('../img/menu-bg.png') bottom center no-repeat;
    width: 120px;
    text-align: center;
    overflow-x: hidden;
    padding: 10px 0 55px;
    z-index: 1;
}
header .logo-container a{
    display: inline-block;
}
header .logo-container img{
    max-width: 60%;
}
.logo-container-profile{
    padding: 2px 20px;
    float: right;
}
.logo-container-profile img{
    height: 50px;
}
header .menu-icon{
    float: left;
    width: 48px;
    padding-top: 12px;
    text-align: center;
    margin-left: 12px;
}
header .menu-icon a{
    display: inline-block;
}
header .menu-icon img{
    width: 32px;
    text-align: center;
}
.login-nav{
    float: right;
    margin-right: 20px;
    width: 120px;
}
.login-nav a, .login-nav button{
    color: #f5ab34;
    text-transform: uppercase;
    display: block;
    text-align: center;
    padding-top: 16px;
    cursor: pointer;
}
.login-button{

}
.header-banner{
    height: 500px;
    background: url('../img/banner1.jpg') center center no-repeat;
    background-size: cover;
    position: relative;
}
.banner-text{
    position: absolute;
    left: 20px;
    top: 40px;
}
.banner-text h5{
    color: #f5ab34;
    font-weight: 400;
    line-height: 12px;
    font-size: 24px;
}
.banner-text h4{
    color: #FFF;
    font-weight: 700;
    font-size: 32px;
}
.banner-ballon{
    background: #f5ab34;
    width: 220px;
    height: 220px;
    position: absolute;
    bottom: -90px;
    left: 0;
    right: 0;
    margin:auto;
    border-radius: 50%;
}
.banner-ballon .cab{
    position: absolute;
    width: 140px;
    top: 36px;
    right: 15px;
}
.banner-ballon h5 {
    color: #243e90;
    left: 20px;
    top: 45px;
    position: absolute;
    font-weight: 400;
}
.banner-ballon h5 span{
    color: #FFF;
}
.banner-ballon h4{
    position: absolute;
    color: #FFF;
    width: 100%;
    text-align: center;
    top: 95px;
    font-size: 35px;
}
.banner-ballon p{
    position: absolute;
    top: 135px;
    color: #243e90;
    width: 100%;
    text-align: center;
    line-height: 17px;
}

.welcome-text-container{
    padding: 130px 30px 50px;
    color: #243e90;
}
.welcome-text-container p{
    text-align: justify;
}
.welcome-text-container h1{
    text-align: center;
    font-weight: 300;
    font-size: 26px;
    margin-bottom: 20px;
}
.register-wrapper{
    position: relative;
    background: #243e90;
    padding: 100px 0 150px;
    overflow: hidden;
    min-height: 300px;
}
.register-wrapper:before, .register-wrapper:after{
    content: '';
    position: absolute;
    transform: rotate(-13deg);
    background: #FFF;
    height: 170px;
    width: 200%;
}
.register-wrapper:before{
    right: 0;
    top: -75px;
}

.register-wrapper:after{
    right: -19%;
    top: 93%;
}
.register-wrapper h1, .buy-share-wrapper h1{
    text-align: center;
    font-weight: 300;
    font-size: 30px;
}
.buy-share-wrapper h2{
    text-align: center;
    font-weight: 600;
    font-size: 26px;
    color: #243e90;
}
.register-wrapper h1{
    color: #FFF;
}
.buy-share-wrapper h1{
    color: #243e90;
}
.register-fieldset{
    background: #FFF;
    border-radius: 8px;
    padding: 12px 16px;
    margin-bottom: 10px;
    border: 1px solid #243e90;
}
.register-fieldset label{
    font-size: 18px;
    color: #243e90;
    display: block;
    width: 100%;
}
.register-fieldset label.small-label, .small-label{
    font-size: 12px;
}
.register-fieldset input[type=text],
.register-fieldset input[type=email],
.register-fieldset input[type=number],
.register-fieldset select,
.register-fieldset textarea,
.register-fieldset input[type=password] {
    font-size: 20px;
    /*appearance: none;*/
    background: none;
    border: 0;
    width: 100%;
    font-weight: 300;
}
.register-fieldset input[type=text],
.register-fieldset input[type=email],
.register-fieldset select,
.register-fieldset input[type=password]{
    appearance: none;
}
.register-fieldset label>span, .change-password-button{
    float: right;
    color: #f2b51b !important;
    font-size: 11px !important;
}
.register-container{
    padding: 16px 20px;
}
.profile-container{
    padding: 16px 0;
}
textarea{
    width: 100%;
    resize: none;
}
.register-container h3{
    color: #FFF;
    margin-bottom: 16px;
    font-weight: 300;
    font-size: 20px;
}
.pin-inputs input{
    width: 20%;
    margin-right: 10px;
    border: 1px solid #243e90;
    border-radius: 6px;
    padding: 6px 0;
    text-align: center;
}
.enter-pin-container{
    padding: 4px;
}
.phone-inputs .extension{
    width: 38%;
    float: left;
}
.phone-inputs .phone-number{
    width: 60%;
    float: right;
}
.clearfix{
    clear: both
}
.register-fieldset-last{
    font-size: 16px;
    color: #FFF;
    padding: 10px 0 20px;
    position: relative
}
.checkbox-wrapper{
    float: left;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    border: 1px solid #FFF;
    text-align: center;
    padding: 5px 0;
}
.checkbox-text{
    float: right;
    width: 87%;
    line-height: 16px;
}
.register-button{
    background: #f5ab34;
    padding: 16px 0;
    display: block;
    border: 0;
    appearance: none;
    text-align: center;
    width: 100%;
    margin-top: 20px;
    border-radius: 2px;
    color: #FFF;
    position: absolute;
    z-index: 2;
}
.buy-share-wrapper{
    padding-bottom: 50px;
}
.buy-share-wrapper-pop{

}
.loading-div{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #243e90;
    color: #FFF;
}
.loading-div p{
    top: 40%;
    position: absolute;
    width: 100%;
    text-align: center;
}
.loading-div p img{
    width: 70px;
}
.buy-share-top{
    position: relative;
    height: 300px;
    margin: 50px 0 30px;
    background: url('../img/money-car.jpg') center center no-repeat;
    background-size: cover;
}
.buy-share-blue-circle, .buy-share-yellow-circle{
    border-radius: 50%;
    position: absolute;
}
.buy-share-blue-circle{
    background: #243e90;
    width: 200px;
    height: 200px;
    left: 20px;
    top: -30px;
    z-index: 2
}
.buy-share-yellow-circle{
    background: #f5ab34;
    width: 180px;
    height: 180px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    color: #243f91;
}
.buy-share-blue-circle h4{
    font-size: 20px;
    color: #f5ab34;
    padding-top: 50px;
    text-align: center;
    font-weight: 300;
}
.buy-share-blue-circle h3{
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    font-weight: 300;
}
.buy-share-yellow-circle h5{
    padding-top: 50px;
    padding-left: 20px;
    font-weight: 300;
}
.buy-share-yellow-circle h4{
    margin-top: -10px;
    padding-left: 0;
    text-align: center;
    font-size: 35px;
    font-weight: 300;
}
.buy-share-yellow-circle h3{
    font-size: 18px;
    padding-left: 4px;
    margin: -10px 30px 0;
    font-weight: 300;
    border-bottom: 1px solid #243f91;
}
.buy-share-yellow-circle p{
    text-align: center;
    font-size: 14px;
}

.select-container select{
    -webkit-appearance: none;
    -moz-appearance: none;
    background: none !important;
}
.select-container{
    position: relative;
    background: #FFF;
    height: 40px;
}
.select-container select{
    width: 100%;
    position: absolute;
    z-index: 2;
}
.select-container:after{
    position: absolute;
    right: 16px;
    top: 8px;
    content: "\f078";
    font-family: FontAwesome;
    font-size: 16px;
    z-index: 1;
}

.contact-message{
    height: 100px;
}
.copy-right{
    padding: 50px 0;
    text-align: center;
    color: #FFF;
    margin-top: 100px;
}
.popup-overlay{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(245, 171,52, .8);
    z-index: 9;
}
.popup-wrapper{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: none;
    z-index: 9;
    overflow-y: auto;
    padding: 40px;
    display: table;
}
.popup{
    display: table-cell;
    vertical-align: top;
    background: #FFF;
    border-radius: 16px;
    overflow: hidden;
    min-height: 200px;
    position: relative;
    padding: 30px 30px 100px;
}
.popup>div{

}
.popup:before, .popup:after{
    background: #243f91;
    position: absolute;
    width: 200px;
    height: 100px;
    content: '';
}
.popup:before{
    left: -60px;
    top: -80px;
    transform: rotate(-43deg);
}
.popup:after{
    right: -60px;
    bottom: -80px;
    transform: rotate(-43deg);
}
.popup h3, .popup h4{
    text-align: center;
    color: #1d315f;
    font-weight: 300;
    margin-top: 30px;
    margin-bottom: 30px;
}
.popup h4{
    font-size: 18px;
}
.popup-close{
    float: right;
    position: absolute;
    right: 20px;
    top: 10px;
}
.popup-close i{
    color: #1d315f;
    font-size: 28px;
}
.main-menu{
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(36, 62,144, .9);
    width: 250px;
    height: 100%;
    z-index: 99;
    padding: 10px 20px;
}
.main-menu ul>li a{
    display: block;
    padding: 8px;
    text-transform: uppercase;
    color: #FFF;
    font-size: 14px;
}

.menu-close-button{
    text-align: left;
    color: #FFF;
    font-size: 28px;
    margin-bottom: 10px;
}
.user-profile-nav{
    width: 60%;
    text-align: center;
    padding: 18px 0 1px;
    color: #f5ab34;
    margin-left: 60px;
}
.show-profile-button{
    color: #f5ab34;
}
.stock-increment{
    color: #39b450;
    font-weight: 300;
    font-size: 24px;
}
.stats-col{
    float: left;
}
.stats-col1{
    width: 65%;
    float: left;
}
.stats-col2{
    width: 35%;
    float: left;
    height: 75px;
    overflow: hidden;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #243e90;
}
.stats-col2 canvas{
    margin-left: -12px;
    /*margin-bottom: -5px;*/
    width: 111% !important;
}
.stats-col3{
    width: 70%;
    font-weight: 300;
    font-size: 24px;
}
.stats-col3 span{
    font-size: 14px !important;
}
.stats-col4{
    text-align: center;
    width: 20%;
}
.stats-row h3{
    font-size: 14px;
    color: #333333;
    font-weight: 300;
}
.stats-row p{
    margin-bottom: 0 !important;
}
.dashboard-box-container2{
    padding: 0 20px;
    clear: both;
}
.dashboard-box-container2 ul>li{
    float: left;
    width: 47%;
    height: 110px;
    box-shadow: 0 0 6px rgba(0,0,0,.1);
    border: 1px solid rgba(0,0,0,.09);
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 4px;
}
.dashboard-box-container2 ul>li:nth-child(2n){
    float: right;
}
.dashboard-box-container2 ul>li i{
    color: #cccccb;
    font-size: 24px;
}

.dashboard-box-container2 ul>li h3{
    color: #333333;
    font-size: 13px;
    font-weight: 300;
}
.dashboard-box-container2 ul>li h4{
    font-size: 20px;
    color: #2eb34c;
    font-weight: 300;
}
.dashboard-box-container2 ul>li h4>span{
    font-size: 11px !important;
}
.big-line-chart-wrapper{
    position: relative;
    /*padding: 20px;*/
    box-shadow: 0 0 6px rgba(0,0,0,.1);
    border: 1px solid rgba(0,0,0,.09);
    margin: 0 20px 20px;
    border-radius: 4px;
    overflow: hidden;
}
.big-line-chart{
    /*height: 179px;*/
    /*padding-bottom: 20px;
    margin-bottom: 20px;*/
    /*overflow: hidden;*/
    /*margin-left: -10px;*/
    margin-bottom: 20px;
    /*padding: 10px 5px;*/
}
.big-line-chart-menu{
    clear: both;
    background: #243e90;
    height: 44px;

    padding: 6px 20px;
    /*margin-right: 6px;*/
    margin-top:0;
}
.big-line-chart-menu ul>li{
    float: left;
    width: 32px;
    height: 30px;
    background: #FFF;
    border: 1px solid #333333;
    border-radius: 4px;
    text-align: center;
    margin-right: 10px;
}
.big-line-chart-menu ul>li, .big-line-chart-menu ul>li{
    width: 32px;
    height: 30px;
    line-height:30px;
    font-size: 14px;
}
.big-line-chart-menu ul>li.current-tab{
    background: #2eb34c !important;
    border: 1px solid #2eb34c;
}
.big-line-chart-menu ul>li.current-tab,.big-line-chart-menu ul>li.current-tab>button{
    color: #FFF !important;
}
.change-password-button{
    width: auto !important;
}