	/*
  	Flaticon icon font: Flaticon
  	Creation date: 13/02/2019 16:36
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff2") format("woff2"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-size: inherit;
	font-style: normal;
}

.flaticon-graphic-design:before { content: "\f100"; }
.flaticon-vector:before { content: "\f101"; }
.flaticon-paint-palette:before { content: "\f102"; }
.flaticon-responsive:before { content: "\f103"; }
.flaticon-layers:before { content: "\f104"; }
.flaticon-layers-1:before { content: "\f105"; }
.flaticon-layers-2:before { content: "\f106"; }
.flaticon-picture:before { content: "\f107"; }
.flaticon-camera:before { content: "\f108"; }
.flaticon-picture-1:before { content: "\f109"; }
.flaticon-picture-2:before { content: "\f10a"; }
.flaticon-apple:before { content: "\f10b"; }
.flaticon-apple-1:before { content: "\f10c"; }
.flaticon-android-logo:before { content: "\f10d"; }
.flaticon-android-character-symbol:before { content: "\f10e"; }
.flaticon-android-logo-1:before { content: "\f10f"; }
.flaticon-windows:before { content: "\f110"; }
.flaticon-windows-8:before { content: "\f111"; }
.flaticon-windows-logo-silhouette:before { content: "\f112"; }
.flaticon-rating:before { content: "\f113"; }
.flaticon-review:before { content: "\f114"; }
.flaticon-review-1:before { content: "\f115"; }
.flaticon-conversation:before { content: "\f116"; }
.flaticon-support:before { content: "\f117"; }
.flaticon-communication:before { content: "\f118"; }
.flaticon-customer-service:before { content: "\f119"; }
.flaticon-chat:before { content: "\f11a"; }
.flaticon-email:before { content: "\f11b"; }
.flaticon-mail:before { content: "\f11c"; }
.flaticon-message:before { content: "\f11d"; }
.flaticon-email-1:before { content: "\f11e"; }
.flaticon-award:before { content: "\f11f"; }
.flaticon-cap:before { content: "\f120"; }
.flaticon-medal:before { content: "\f121"; }
.flaticon-trophy:before { content: "\f122"; }
.flaticon-trophy-1:before { content: "\f123"; }
.flaticon-badge:before { content: "\f124"; }
.flaticon-trophy-2:before { content: "\f125"; }
.flaticon-settings:before { content: "\f126"; }
.flaticon-settings-1:before { content: "\f127"; }
.flaticon-tools:before { content: "\f128"; }
.flaticon-customer-support:before { content: "\f129"; }
.flaticon-settings-2:before { content: "\f12a"; }
.flaticon-shield:before { content: "\f12b"; }
.flaticon-shield-1:before { content: "\f12c"; }
.flaticon-checked:before { content: "\f12d"; }
.flaticon-analytics:before { content: "\f12e"; }
.flaticon-conversation-1:before { content: "\f12f"; }
.flaticon-speech-bubble:before { content: "\f130"; }
.flaticon-chat-1:before { content: "\f131"; }
.flaticon-database:before { content: "\f132"; }
.flaticon-data-protection:before { content: "\f133"; }
.flaticon-cloud:before { content: "\f134"; }